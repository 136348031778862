import React from 'react'
import clsx from 'clsx'

const MaxWidthContainer = ({ id = '', className = '', ...props }) => {
	return (
		<div
			id={id}
			className={clsx(
				'px-4 max-w-xl md:max-w-3xl xl:max-w-7xl mx-auto',
				className
			)}
			{...props}
		/>
	)
}

export default MaxWidthContainer
