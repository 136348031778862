import React from 'react'
import { FiChevronRight } from 'react-icons/fi'

interface ICTAContactProps {
	//
}

const CTAContact = ({}: ICTAContactProps) => {
	// #region STATE
	// #endregion STATE

	// #region HOOKS
	// #endregion HOOKS

	// #region COMPUTED
	// #endregion COMPUTED

	// #region FUNCTIONS
	// #endregion FUNCTIONS

	// #region EFFECTS
	// #endregion EFFECTS

	return (
		<a
			href='/#contact-form'
			className='flex flex-row items-center text-xs bg-white text-dark px-4 py-2 rounded-full'
		>
			Contact Us
			{/* <FiChevronRight className='ml-1 text-base' /> */}
		</a>
	)
}

export default CTAContact
